<template>
  <div class="time-sheet-driver">
    <div class="form-area table-search">
      <el-form
        :inline="true"
        ref="form"
        :model="form"
        label-width="82px"
        label-position="left"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item label="开始时间：" prop="settlementTime">
          <el-date-picker
            class="marginR"
            v-model="form.settlementTime"
            type="date"
            placeholder="选择日期时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" prop="settlementEndTime">
          <el-date-picker
            class="marginR"
            v-model="form.settlementEndTime"
            type="date"
            placeholder="选择日期时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          ></el-date-picker>
        </el-form-item>
        <!-- 所属线路 -->
        <el-form-item label="所属线路：" prop="companyId">
          <el-select
            v-model="form.companyId"
            placeholder="请选择线路"
            @change="checBusDriverByline"
          >
            <el-option
              v-for="item in busLineList"
              :key="item.companyId"
              :label="item.companyName"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 司机 -->
        <el-form-item label="选择司机：" prop="credentialsNos">
          <el-select
            v-model="form.credentialsNos"
            multiple
            collapse-tags
            placeholder="请选择司机"
          >
            <el-option
              v-for="item in driversOptions"
              :key="item.credentialsNo"
              :label="item.driverName"
              :value="item.credentialsNo"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="exportFile"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <el-tabs
      type="border-card"
      v-model="form.settlementType"
      @tab-click="clickTab"
    >
      <el-tab-pane label="日报" name="0">
        <el-row class="driver-manage-box">
          <el-col :span="24" class="table-box">
            <el-table
              :data="tableData"
              :span-method="objectSpanMethod"
              :header-cell-style="{
                background: '#fff',
                color: '#999999',
                padding: '1vh 0',
                textAlign: 'center',
              }"
              height="calc(100vh - 360px)"
            >
              <el-table-column
                prop="settlementTime"
                label="日期"
                width="100"
              ></el-table-column>
              <el-table-column prop="driverName" label="司机"></el-table-column>
              <el-table-column
                prop="cph"
                label="车牌号"
                width="100"
              ></el-table-column>
              <el-table-column prop="done" label="行驶趟次">
                <el-table-column
                  prop="done.normal"
                  label="正常"
                ></el-table-column>
                <el-table-column
                  prop="done.energy"
                  label="加气（油、电）"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="done.repair"
                  label="维修"
                ></el-table-column>
                <el-table-column
                  prop="done.chartered"
                  label="包车"
                ></el-table-column>
                <el-table-column
                  prop="done.other"
                  label="其他"
                ></el-table-column>
              </el-table-column>
              <el-table-column
                prop="completedTotalTimes"
                label="行驶趟次(合计)"
              ></el-table-column>
              <el-table-column
                prop="runMile"
                label="行驶里程数"
              ></el-table-column>
              <el-table-column
                prop="runTotalMile"
                label="行驶里程数(合计)"
              ></el-table-column>
              <el-table-column
                prop="operateRunMile"
                label="营运里程"
              ></el-table-column>
              <el-table-column
                prop="operateRunTotalMile"
                label="营运里程(合计)"
              >
                <template slot-scope="scope">
                  {{ scope.row.operateRunTotalMile | filterRunTotal }}
                </template>
              </el-table-column>
              <el-table-column
                prop="notOperateRunMile"
                label="非营运里程"
              ></el-table-column>
              <el-table-column
                prop="notOperateRunTotalMile"
                label="非营运里程(合计)"
              ></el-table-column>
              <el-table-column
                prop="oilConsumption"
                label="油耗"
              ></el-table-column>
              <el-table-column
                prop="oilConsumptionTotal"
                label="油耗(合计)"
              ></el-table-column>
            </el-table>
            <my-page
              ref="pagination"
              @sendInfo="currentPageChange"
              :pageTotal="total"
              class="pagination"
            ></my-page>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="月报" name="1">
        <el-row class="driver-manage-box">
          <el-col :span="24" class="table-box">
            <el-table
              :data="tableData"
              :span-method="objectSpanMethodMonth"
              :header-cell-style="{
                background: '#fff',
                color: '#999999',
                padding: '1vh 0',
                textAlign: 'center',
              }"
              height="calc(100vh - 360px)"
            >
              <el-table-column
                prop="settlementTime"
                label="日期"
                width="200"
              ></el-table-column>
              <el-table-column prop="driverName" label="司机"></el-table-column>
              <el-table-column
                prop="cph"
                label="车牌号"
                width="100"
              ></el-table-column>
              <el-table-column
                prop="attendance"
                label="出勤天数"
              ></el-table-column>
              <el-table-column
                prop="attendanceTotal"
                label="出勤天数(合计)"
              ></el-table-column>
              <el-table-column prop="done" label="行驶趟次">
                <el-table-column
                  prop="done.normal"
                  label="正常"
                ></el-table-column>
                <el-table-column
                  prop="done.energy"
                  label="加气（油、电）"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="done.repair"
                  label="维修"
                ></el-table-column>
                <el-table-column
                  prop="done.chartered"
                  label="包车"
                ></el-table-column>
                <el-table-column
                  prop="done.other"
                  label="其他"
                ></el-table-column>
              </el-table-column>
              <el-table-column
                prop="completedTotalTimes"
                label="行驶趟次(合计)"
              ></el-table-column>
              <el-table-column
                prop="runMile"
                label="行驶里程数"
              ></el-table-column>
              <el-table-column
                prop="runTotalMile"
                label="行驶里程数(合计)"
              ></el-table-column>
              <el-table-column
                prop="operateRunMile"
                label="营运里程"
              ></el-table-column>
              <el-table-column
                prop="operateRunTotalMile"
                label="营运里程(合计)"
              >
                <template slot-scope="scope">
                  {{ scope.row.operateRunTotalMile | filterRunTotal }}
                </template>
              </el-table-column>
              <el-table-column
                prop="notOperateRunMile"
                label="非营运里程"
              ></el-table-column>
              <el-table-column
                prop="notOperateRunTotalMile"
                label="非营运里程(合计)"
              ></el-table-column>
            </el-table>
            <my-page
              ref="pagination"
              @sendInfo="currentPageChange"
              :pageTotal="total"
              class="pagination"
            ></my-page>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { queryAllBusLineList, getDriverByline } from '@/api/lib/bus-api.js';
import { ListSettlementsNew, DriverqueryByCompanyId } from '@/api/lib/report-api.js';
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import pagination from '@/components/pagination/pagination.vue'
import {
  getStartDate,
  getCurentDate,
  checkTimeLimit,
  checkLimitDate,
  formatDate,
  formatDict,
  getCurent
} from '@/common/utils/index'
export default {
  components: {
    'my-page': pagination,
  },
  data () {
    return {
      form: {
        type: 0,//司机结算：0 车辆结算：1
        currentPage: 1,
        pageSize: 10,
        settlementType: 0,//0：日报 1：月报
        settlementTime: null, // 开始时间
        settlementEndTime: null, // 结束时间
        companyId: '',//线路ID
        credentialsNos: [],//选择司机
      },
      total: 0,//总条数
      busLineList: [],//线路列表
      driversOptions: [],//驾驶员列表
      spanArr: [],
      tableData: [],
      downLoadStatus: false,
      rules: {
        settlementTime: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        settlementEndTime: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        credentialsNos: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      pickerOptions: {
        disabledDate (time) {
          return checkLimitDate(time)
        },
        shortcuts: [
          {
            text: '今天',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3400 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
    }
  },
  filters: {
    filterRunTotal (val) {
      return Math.round(val * 10) / 10
    }
  },
  created () {
    this.queryAllBusLine();//获取所有线路
  },
  methods: {
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4 || columnIndex === 10 || columnIndex === 16 || columnIndex === 18 || columnIndex === 20 || columnIndex === 22 || columnIndex === 24) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col
        }
      }
    },
    objectSpanMethodMonth ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4 || columnIndex === 6 || columnIndex === 12 || columnIndex === 18
        || columnIndex === 20 || columnIndex === 22 || columnIndex === 24 || columnIndex === 26) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
          rowspan: _row,
          colspan: _col
        }
      }
    },
    getSpanArr (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同
          if (data[i].driverName === data[i - 1].driverName && data[i].settlementTime === data[i - 1].settlementTime) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    exportFile () {
      this.downLoadStatus = true;
      let param = { ...this.form }
      delete param.currentPage
      delete param.pageSize
      const data = {
        baseUrl: 'report',
        fileName: `司机结算-${this.form.settlementType == 0 ? '日' : '月'}-${this.form.settlementTime}-${this.form.settlementEndTime}`,
        queryParam: JSON.stringify(param),
        queryPath: '/settlements/exportNew'
      }
      addExcelExport(data).then((res) => {
        this.$message.success(res.msg)
        this.downLoadStatus = false
        this.$store.dispatch('setRefreshStatus', true)
      })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    getSettlementsListFn () {
      this.spanArr = [];
      let data = { ...this.form }
      ListSettlementsNew(data).then(res => {
        if (res.code === 1000) {
          this.total = res.data.total;
          this.tableData = res.data.list;
          this.getSpanArr(this.tableData);
        } else if (res.code === 1100) {
          this.tableData = [];
          this.$message.warning(res.msg);
        }

      })
    },
    clickTab (tab, event) {
      this.tableData = [];
      this.form.settlementType = tab.name;
      this.form.currentPage = 1;
      this.search()
    },
    checBusDriverByline () {
      var self = this;
      self.form.credentialsNos = [];
      self.driversOptions = [];
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      })
      DriverqueryByCompanyId(self.form.companyId)
        .then(res => {
          if (res.data.length == 0) {
            // self.$message.warning("所有司机都已绑定，当前无司机可选择");
          } else {
            self.driversOptions = res.data;
            res.data.forEach(element => {
              self.form.credentialsNos.push(element.credentialsNo)
            });
          }
        })
        .catch(res => { });
    },
    search () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.settlementTime > this.form.settlementEndTime) {
            this.$message.warning("开始日期必须小于结束日期");
          } else {
            this.getSettlementsListFn();
          }
        }
      })
    },
    //获取所有线路
    queryAllBusLine () {
      queryAllBusLineList().then((res) => {
        if (res.code === 1000) {
          if (res.data.length == 0) {
            this.$message.warning("还没有线路信息，请先添加线路信息");
          } else {
            this.busLineList = res.data
          }
        }
      })
    },
    // 分页参数改变
    currentPageChange (val) {
      let { pageSize, currentPage } = val;
      this.form.currentPage = currentPage;
      this.form.pageSize = pageSize;
      this.getSettlementsListFn();
    }
  }
}
</script>
<style lang="scss" scoped>
.time-sheet-driver {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.time-sheet-driver {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
/deep/.el-form-item {
  margin-bottom: 16px;
}
</style>